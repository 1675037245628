import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Button from 'reusecore/src/elements/Button';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import ClientBlock from 'common/src/components/ClientBlock';
import Container from 'common/src/components/UI/Container';
import FeatureSectionWrapper from './featureSection.style';

const ClientsSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  featureTitle,
  featureDescription,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
  button,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        Clients {
          id
          title
          description
          logo {
            childImageSharp {
              fixed(height: 72) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `);

  return (
    <FeatureSectionWrapper id="clients_section">
      <Container>
        <Box {...sectionHeader}>
          <Text content="CLIENTS AND PARTNERS" {...sectionSubTitle} />
          <Heading content="Our work." {...sectionTitle} />
        </Box>
        <Box className="row" {...row}>
          {Data.saasJson.Clients.map((feature, index) => (
            <Box className="col" {...col} key={index}>
              <ClientBlock
                logo={feature.logo.childImageSharp.fixed}
                wrapperStyle={blockWrapperStyle}
                iconStyle={iconStyle}
                contentStyle={contentStyle}
                title={
                  <Heading
                    content={
                      <span
                        dangerouslySetInnerHTML={{ __html: feature.title }}
                      />
                    }
                    {...featureTitle}
                  />
                }
                description={
                  <Text
                    content={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: feature.description,
                        }}
                      />
                    }
                    {...featureDescription}
                  />
                }
                // button={
                //   index === 0 ? (
                //     <a href="https://www.europanb.com/" target="_blank">
                //       <Button {...button} title="DISCOVER" />
                //     </a>
                //   ) : index === 1 ? (
                //     <a
                //       href="mailto:enquiry@europa-labs.com?subject=Europa+Notebook+Private"
                //       target="_blank"
                //     >
                //       <Button {...button} title="ENQUIRE" />
                //     </a>
                //   ) : (
                //     <a
                //       href="mailto:enquiry@europa-labs.com?subject=Notebook+powered+Data+Catalogs"
                //       target="_blank"
                //     >
                //       <Button {...button} title="ENQUIRE" />
                //     </a>
                //   )
                // }
                className="saasFeature"
              />
            </Box>
          ))}
        </Box>
      </Container>
    </FeatureSectionWrapper>
  );
};

// ClientsSection style props
ClientsSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
  button: PropTypes.object,
};

// ClientsSection default style
ClientsSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['40px', '40px', '40px', '80px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 3, 1 / 3],
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['30px', '20px', '20px', '20px'],
  },
  // feature icon default style
  iconStyle: {
    width: ['70px', '75px', '75px', '84px'],
    height: ['70px', '75px', '75px', '84px'],
    borderRadius: '50%',
    bg: '#93d26e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: ['32px', '36px'],
    color: '#ffffff',
    overflow: 'hidden',
    mb: ['20px', '20px', '20px', '30px'],
    borderBottomLeftRadius: '50%',
  },
  // feature content default style
  contentStyle: {
    textAlign: 'left',
  },
  // feature title default style
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    mb: ['10px', '10px', '10px', '20px'],
    letterSpacing: '-0.020em',
  },
  // feature description default style
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.75',
    color: '#343d4ccc',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: '46px',
  },
};

export default ClientsSection;
